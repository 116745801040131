import React, { FC, JSX, useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  IndicatorsAllowedParams,
  indicatorsItemTranslate,
  IndicatorsPanelStore,
  IndicatorsTabMap,
  IndicatorVariants,
  transformIndicatorsQueryToAllowedParams,
} from '../../store/IndicatorsPanelStore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link as MxLink } from '@mx-ui/ui';
import { formatDateSwagger, formatNumber3, formatPriceCur } from '@mx-ui/helpers';
import IconButton from '@mui/material/IconButton';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { CustomPopper } from '../../views/deals/components/CustomPopper';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { CheckboxAsInput } from './CustomBoxAsInput/CustomBoxAsInput';
import Box from '@mui/system/Box';
import { useRootStore } from '../../store/MobxStoreProvider';

export const indicatorsColor = {
  deal: '#D6F7B4',
  dealSubmitted: '#B1D399',
  bill: '#8AAA75',
  billReserved: '#6F832E',
  shipment: '#324E21',
};
export const namesRatingTabs: Record<IndicatorVariants, string> = {
  periodic: 'Периодическая',
  conversion: 'Конверсионная',
};
export const HomeIndicatorsReport: FC<{ isGridEditing: boolean }> = observer(({ isGridEditing }): JSX.Element => {
  const rootStore = useRootStore();
  const router = rootStore.getRouter();
  const indicatorsStore = useMemo(() => {
    const indicatorsStore = new IndicatorsPanelStore(rootStore);
    const initRequest: IndicatorsAllowedParams = transformIndicatorsQueryToAllowedParams<IndicatorsAllowedParams>(router.query());
    indicatorsStore.setRouterControl(false);
    indicatorsStore.setRequestFromObj(initRequest);
    indicatorsStore.actualizeRouter(initRequest);
    indicatorsStore.setDate(new Date(initRequest.dateFrom), new Date(initRequest.dateTo));
    indicatorsStore.initialLoadStatsByQuery(initRequest, true).then(() => {
      indicatorsStore.loadStatIndicatorsStat(indicatorsStore.mode);
    });
    return indicatorsStore;
  }, [rootStore]);

  const ratioKeys = Object.keys(indicatorsStore?.indicatorsStatsRatio);
  const [selectedTab, setSelectedTab] = useState<IndicatorVariants>(indicatorsStore.indicatorVariant || 'periodic');
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const requestDateFrom = new Date(indicatorsStore.request.dateFrom);
  const month = requestDateFrom.toLocaleString('ru-RU', { month: 'long' });
  const handleDialogOpen = (): void => {
    setIsDialogOpen(!isDialogOpen);
  };
  const handleTabChange = (event: React.ChangeEvent<unknown>, indicatorVariant: IndicatorVariants): void => {
    setSelectedTab(indicatorVariant);
    indicatorsStore.setIndicatorVariant(indicatorVariant);
  };

  const handleChangeSwitch = useCallback((): void => {
    const currentViewMode = indicatorsStore.request.tab || IndicatorsTabMap.weight;
    const newViewMode = currentViewMode === IndicatorsTabMap.weight ? IndicatorsTabMap.cost : IndicatorsTabMap.weight;
    indicatorsStore.setIndicatorsTab(newViewMode);
    setIsDialogOpen(false);
  }, [indicatorsStore]);
  const graph = indicatorsStore.graph?.[selectedTab];

  const indicatorsEntriesChecked = useMemo(() => {
    return Object.entries(indicatorsStore?.indicatorsStatsChecked);
  }, [indicatorsStore.request.tab, indicatorsStore?.indicatorsStatsChecked]);
  const handleChangeIndicatorsItem = (indicatorName: string): void => {
    indicatorsStore.setIndicatorsItemChecked(indicatorName);
  };
  const firstIndex = indicatorsStore.indexesOfIndicatorsItemCheckedGet.first;
  const lastIndex = indicatorsStore.indexesOfIndicatorsItemCheckedGet.last;

  return (
    <>
      <Grid item md={12} height="100%" sm={12} flex="1 1 auto">
        <Paper sx={{ height: '100%', width: '100%', padding: 1 }}>
          <Grid container flexDirection={'column'} flexWrap={'nowrap'} height="100%">
            <Grid item width={'100%'}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <MxLink
                    href={
                      isGridEditing
                        ? undefined
                        : `/app/report/indicators?tab=${indicatorsStore.request.tab}&quickRange=${
                            indicatorsStore.request.quickRange || 'current_month'
                          }&dateFrom=${formatDateSwagger(indicatorsStore.request.dateFrom) || ''}&dateTo=${
                            formatDateSwagger(indicatorsStore.request.dateTo) || ''
                          }&mode=${indicatorsStore.mode}`
                    }
                    variant="h6"
                    color="inherit"
                    typographySx={{ cursor: isGridEditing ? 'initial' : 'pointer' }}
                  >
                    {`Воронка продаж${month ? ', ' + month : ''}`}
                  </MxLink>
                </Grid>
                <Grid item>
                  <IconButton ref={anchorEl} color="primary" onClick={handleDialogOpen} component="span" size="small">
                    <MoreVertSharpIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item width={'100%'} mb={2}>
              <TabContext value={selectedTab}>
                <Grid item width={'100%'}>
                  <TabList onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
                    {Object.keys(namesRatingTabs).map(tab => (
                      <Tab label={namesRatingTabs[tab]} value={tab} key={tab} />
                    ))}
                  </TabList>
                </Grid>
                <Grid item width={'100%'}>
                  <TabPanel sx={{ padding: 0 }} value={'periodic'} key={'periodic'}></TabPanel>
                </Grid>
                <Grid item width={'100%'}>
                  <TabPanel sx={{ padding: 0 }} value={'conversion'} key={'conversion'}></TabPanel>
                </Grid>
              </TabContext>
            </Grid>

            <Grid item width={'100%'}>
              <Grid container>
                {selectedTab === 'periodic'
                  ? [0, 1, 2, 3, 4].map((item, index) => {
                      const isChecked = !!indicatorsStore?.indicatorsStatsChecked?.[indicatorsEntriesChecked?.[item][0]];
                      return (
                        <Grid key={item} item xs={12} mb={1} height={'45px'} position={'relative'}>
                          <Grid container alignItems={'center'} flexWrap={'nowrap'}>
                            <Grid item flex={'0 0 auto'} mx={2} position={'relative'}>
                              <CheckboxAsInput
                                indicatorName={indicatorsEntriesChecked[item][0]}
                                disabled={indicatorsStore.isLoading}
                                onChange={handleChangeIndicatorsItem}
                                isChecked={isChecked}
                              />
                              {(index >= firstIndex && index < lastIndex && (
                                <Box
                                  position={'absolute'}
                                  sx={{
                                    height: '40px',
                                    borderLeft: '1px solid #868383',
                                    left: '7px',
                                    top: '20px',
                                  }}
                                />
                              )) ||
                                null}
                            </Grid>
                            <Grid item flex={'1 1 auto'}>
                              <Grid container>
                                <Grid item>
                                  <Typography variant={'body1'} component="span" fontWeight={500}>
                                    {indicatorsItemTranslate[ratioKeys[item]]}
                                  </Typography>
                                  <Typography variant={'body2'} component="span">{` (${
                                    typeof graph?.counts?.[ratioKeys[item]] === 'number' ? graph?.counts?.[ratioKeys[item]] : '0'
                                  }) ${
                                    !indicatorsStore.isLoading
                                      ? indicatorsStore.request.tab === 'cost'
                                        ? formatPriceCur(graph?.costs?.[ratioKeys[item]])
                                        : formatNumber3(graph?.weights?.[ratioKeys[item]]) + ' т'
                                      : ''
                                  }`}</Typography>
                                </Grid>
                                <Grid item xs={12} height={'20px'}>
                                  <Box
                                    bgcolor={indicatorsColor[ratioKeys?.[item]]}
                                    height={'100%'}
                                    sx={{
                                      width: indicatorsStore.isLoading
                                        ? '50%'
                                        : `${indicatorsStore?.indicatorsStatsRatio?.[ratioKeys[item]]}%`,
                                      borderRadius: '0 4px 4px 0',
                                      transition: 'all ease .4s',
                                      opacity: indicatorsStore.isLoading ? 0.3 : 1,
                                      minWidth: indicatorsStore?.indicatorsStatsRatio?.[ratioKeys[item]] ? '40px' : 0,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {(!indicatorsStore.isLoading &&
                            typeof lastIndex === 'number' &&
                            typeof firstIndex === 'number' &&
                            index === lastIndex && (
                              <Grid
                                position={'absolute'}
                                px={0.5}
                                sx={{
                                  bottom: '0px',
                                  right: '0px',
                                  backgroundColor: theme => (theme.palette.mode === 'light' ? '#fff' : '#242424'),
                                  borderRadius: '5px',
                                  boxShadow: theme =>
                                    theme.palette.mode === 'light' ? '0px 0px 15px 0px rgb(255 255 255)' : '0px 0px 15px 0px rgb(0 0 0)',
                                }}
                              >
                                <Typography fontSize={28} sx={{ color: theme => (theme.palette.mode === 'light' ? '#676767' : '#e0e0e0') }}>
                                  {indicatorsStore.request.tab === 'cost'
                                    ? Math.round(
                                        ((graph?.costs?.[ratioKeys[lastIndex]] || 0) / (graph?.costs?.[ratioKeys[firstIndex]] || 1)) * 100
                                      ) + ' %'
                                    : Math.round(
                                        ((graph?.weights?.[ratioKeys[lastIndex]] || 0) / (graph?.weights?.[ratioKeys[firstIndex]] || 1)) *
                                          100
                                      ) + ' %'}
                                </Typography>
                              </Grid>
                            )) ||
                            null}
                        </Grid>
                      );
                    })
                  : [0, 1, 2, 3, 4].map((item, index) => {
                      const isChecked = !!indicatorsStore?.indicatorsStatsChecked?.[indicatorsEntriesChecked?.[index][0]];
                      return (
                        <Grid key={item} item xs={12} mb={1} height={'45px'} position={'relative'}>
                          <Grid container alignItems={'center'} flexWrap={'nowrap'}>
                            <Grid item flex={'1 1 auto'}>
                              <Grid container justifyContent={'flex-end'}>
                                <Grid item>
                                  <Typography variant={'body1'} component="span" fontWeight={500}>
                                    {indicatorsItemTranslate[ratioKeys[item]]}
                                  </Typography>
                                  <Typography variant={'body2'} component="span">{` (${
                                    typeof graph?.counts?.[ratioKeys[item]] === 'number' ? graph?.counts?.[ratioKeys[item]] : '0'
                                  }) ${
                                    !indicatorsStore.isLoading
                                      ? indicatorsStore.request.tab === 'cost'
                                        ? formatPriceCur(graph?.costs?.[ratioKeys[item]])
                                        : formatNumber3(graph?.weights?.[ratioKeys[item]]) + ' т'
                                      : ''
                                  }`}</Typography>
                                </Grid>
                                <Grid item xs={12} height={'20px'} sx={{ direction: 'rtl' }}>
                                  <Box
                                    bgcolor={'transparent'}
                                    display={'flex'}
                                    height={'100%'}
                                    sx={{
                                      width: `${
                                        indicatorsStore.isLoading
                                          ? 50
                                          : indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].total
                                      }%`,
                                      borderRadius: '4px 0 0 4px',
                                      transition: 'all ease .4s',
                                      overflow: 'hidden',
                                      minWidth:
                                        indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].colored &&
                                        indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].delta
                                          ? '80px'
                                          : indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].colored ||
                                            indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].delta
                                          ? '40px'
                                          : '0',
                                    }}
                                  >
                                    <Box
                                      bgcolor={indicatorsColor[ratioKeys?.[item]]}
                                      height={'100%'}
                                      sx={{
                                        width: indicatorsStore.isLoading
                                          ? '50%'
                                          : `${indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].colored}%`,
                                        transition: 'all ease .4s',
                                        opacity: indicatorsStore.isLoading ? 0.3 : 1,
                                        display: 'inline-block',
                                        position: 'relative',
                                        minWidth: indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].colored ? '40px' : '0',
                                      }}
                                    >
                                      {(indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].colored && (
                                        <Typography
                                          variant={'caption'}
                                          position={'absolute'}
                                          sx={{
                                            left: '5px',
                                            top: '0',
                                            color:
                                              item === 0 || item === 1 || item === 2
                                                ? indicatorsColor[ratioKeys?.[4]]
                                                : item === 4 || item === 3
                                                ? indicatorsColor[ratioKeys?.[0]]
                                                : 'inherit',
                                          }}
                                        >
                                          {indicatorsStore.isLoading
                                            ? ''
                                            : `% ${indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].colored}`}
                                        </Typography>
                                      )) ||
                                        null}
                                    </Box>
                                    <Box
                                      bgcolor={'#bdbdbd'}
                                      height={'100%'}
                                      sx={{
                                        width: indicatorsStore.isLoading
                                          ? '50%'
                                          : `${indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].delta}%`,
                                        transition: 'all ease .4s',
                                        opacity: indicatorsStore.isLoading ? 0.3 : 1,
                                        display: 'inline-block',
                                        position: 'relative',
                                        borderRadius: '4px 0 0 4px',
                                        minWidth: indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].delta ? '40px' : 0,
                                      }}
                                    >
                                      {(indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].delta && (
                                        <Typography
                                          variant={'caption'}
                                          position={'absolute'}
                                          sx={{
                                            left: '4px',
                                            top: '0',
                                            color: indicatorsColor[ratioKeys?.[4]],
                                          }}
                                        >
                                          {indicatorsStore.isLoading
                                            ? ''
                                            : `% ${indicatorsStore?.indicatorsStatsRatioConversion?.[ratioKeys[item]].delta}`}
                                        </Typography>
                                      )) ||
                                        null}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item flex={'0 0 auto'} mx={2} position={'relative'}>
                              <CheckboxAsInput
                                indicatorName={indicatorsEntriesChecked[index][0]}
                                disabled={indicatorsStore.isLoading}
                                onChange={handleChangeIndicatorsItem}
                                isChecked={isChecked}
                              />
                              {(index >= firstIndex && index < lastIndex && (
                                <Box
                                  position={'absolute'}
                                  sx={{
                                    height: '40px',
                                    borderLeft: '1px solid #868383',
                                    left: '7px',
                                    top: '20px',
                                  }}
                                />
                              )) ||
                                null}
                            </Grid>
                          </Grid>
                          {(!indicatorsStore.isLoading &&
                            typeof lastIndex === 'number' &&
                            typeof firstIndex === 'number' &&
                            index === lastIndex && (
                              <Grid
                                position={'absolute'}
                                px={0.5}
                                sx={{
                                  bottom: '0px',
                                  left: '0px',
                                  backgroundColor: theme => (theme.palette.mode === 'light' ? '#fff' : '#242424'),
                                  borderRadius: '5px',
                                  boxShadow: theme =>
                                    theme.palette.mode === 'light'
                                      ? '0px 0px 15px 0px rgb(255 255 255)'
                                      : '0px 0px 15px 0px 5px rgb(0 0 0)',
                                }}
                              >
                                <Typography fontSize={28} sx={{ color: theme => (theme.palette.mode === 'light' ? '#676767' : '#e0e0e0') }}>
                                  {indicatorsStore.request.tab === 'cost'
                                    ? Math.round(
                                        ((graph?.costs?.[ratioKeys[lastIndex]] || 0) / (graph?.costs?.[ratioKeys[firstIndex]] || 1)) * 100
                                      ) + ' %'
                                    : Math.round(
                                        ((graph?.weights?.[ratioKeys[lastIndex]] || 0) / (graph?.weights?.[ratioKeys[firstIndex]] || 1)) *
                                          100
                                      ) + ' %'}
                                </Typography>
                              </Grid>
                            )) ||
                            null}
                        </Grid>
                      );
                    })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <CustomPopper
        isOpen={isDialogOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsDialogOpen(false)}
        placement="bottom-end"
        sx={{ minWidth: '200px' }}
      >
        <>
          <MenuItem onClick={handleChangeSwitch} selected={indicatorsStore.request.tab === IndicatorsTabMap.weight}>
            Объем
          </MenuItem>
          <MenuItem onClick={handleChangeSwitch} selected={indicatorsStore.request.tab === IndicatorsTabMap.cost}>
            Стоимость
          </MenuItem>
        </>
      </CustomPopper>
    </>
  );
});
