import React, { ChangeEvent, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Checkbox } from '@mui/material';

interface CheckboxAsInputProps {
  isChecked: boolean;
  indicatorName: string;
  onChange: (indicatorName: string) => void;
  disabled: boolean;
  className?: string;
}

export const CheckboxAsInput: FC<CheckboxAsInputProps> = observer((props): JSX.Element => {
  const { isChecked, onChange, disabled, indicatorName, className } = props;

  const handleContainerClick = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) onChange(indicatorName);
  };

  const handleContainerChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleContainerClick}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: disabled ? 'inherit' : 'pointer',
        }}
        className={className}
        onClick={handleContainerClick}
      >
        <Checkbox
          checked={isChecked}
          disabled={disabled}
          onChange={handleContainerChange}
          sx={{
            position: 'absolute',
            width: 0,
            height: 0,
            margin: 0,
            padding: 0,
            border: 'none',
            clip: 'rect(0 0 0 0)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
        <Box
          className="styled-checkbox"
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: 16,
            height: 16,
            border: '1px solid #322e2e',
            backgroundColor: '#fff',
            borderRadius: '50%',
            transition: 'all 0.2s ease',
            transform: isChecked ? 'scale(1.15)' : 'scale(1)',
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'inherit' : 'pointer',
            zIndex: 10,
            '&:hover': {
              transform: disabled ? 'inherit' : isChecked ? 'scale(1.25)' : 'scale(1.15)',
            },
            '&::before': {
              position: 'absolute',
              content: '""',
              top: '50%',
              left: '50%',
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: '#322e2e',
              transition: 'all 0.2s ease',
              transform: isChecked ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0)',
            },
          }}
        />
      </Box>
    </form>
  );
});
